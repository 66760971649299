import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import SEO from "../components/seo"
import { UncontrolledCarousel } from "reactstrap"
import lifting from "../images/services/lifting/lifting-06.jpg"
import detox from "../images/services/detoxifying/detoxifying-01.jpg"
import eyes from "../images/services/eyes/miracle-eyes-01.jpg"
import head from "../images/services/head/hermit-head-02.jpg"
import muscle from "../images/services/lifting/muscle-workout-01.jpg"

const IndexPage = props => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          author
          mobile
          facebook
          instagram
          line
        }
      }
      gallery0101: file(relativePath: { eq: "gallery/gallery-01-01.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      gallery0102: file(relativePath: { eq: "gallery/gallery-01-02.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      gallery0103: file(relativePath: { eq: "gallery/gallery-01-03.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      
      gallery0201: file(relativePath: { eq: "gallery/gallery-02-01.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      gallery0202: file(relativePath: { eq: "gallery/gallery-02-02.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      gallery0203: file(relativePath: { eq: "gallery/gallery-02-03.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      gallery0301: file(relativePath: { eq: "gallery/gallery-03-01.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      gallery0302: file(relativePath: { eq: "gallery/gallery-03-02.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      gallery0303: file(relativePath: { eq: "gallery/gallery-03-03.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      gallery0401: file(relativePath: { eq: "gallery/gallery-04-04.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      gallery0402: file(relativePath: { eq: "gallery/gallery-04-02.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      gallery0403: file(relativePath: { eq: "gallery/gallery-04-03.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      skincheckup: file(
        relativePath: { eq: "services/checkup/checkup-01.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      lifting: file(relativePath: { eq: "services/lifting/lifting-02.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      detox: file(
        relativePath: { eq: "services/detoxifying/detoxifying-02.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      hermithead: file(
        relativePath: { eq: "services/head/hermit-head-03.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      miracleeyes: file(
        relativePath: { eq: "services/eyes/miracle-eyes-03.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const items = [
    {
      src: lifting,
      altText: "นวดหน้ายกกระชับ V-Shape (Make Me Lift)",
      caption: "นวดหน้ายกกระชับ V-Shape (Make Me Lift)",
      header: "Lifting Solution",
      key: "1",
    },
    {
      src: detox,
      altText: "นวดหน้าขจัดสารพิษ (Make Me Bright)",
      caption: "นวดหน้าขจัดสารพิษ (Make Me Bright)",
      header: "Detoxyfying Balance",
      key: "2",
    },
    {
      src: head,
      altText: "นวดศรีษะ (Hermit's Head)",
      caption: "นวดศรีษะ (Hermit's Head)",
      header: "Hermit's Head",
      key: "3",
    },
    {
      src: eyes,
      altText: "นวดรอบดวงตา (Make Me Eye Bright)",
      caption: "นวดรอบดวงตา (Make Me Eye Bright)",
      header: "Make Me Eye Bright With Five Serum",
      key: "4",
    },
    {
      src: muscle,
      altText: "นวดออกกำลังกาย กล้ามเนื้อบนในหน้า",
      caption: "นวดออกกำลังกาย กล้ามเนื้อบนในหน้า",
      header: "Muscle Workout",
      key: "5",
    },
  ]

  return (
    <Layout>
      <SEO
        title={`${data.site.siteMetadata.author} | ${data.site.siteMetadata.title}`}
      />
      <UncontrolledCarousel items={items} />
      <div className="container mt-5">
        <p className="title text-justify">
          ร่างกาย ผิวหน้านั่นเปลี่ยนแปลงตามวัยและอายุ เมื่ออายุมากขึ้น
          ผิวเริ่มหย่อนคล้อย ไม่กระชับเหมือนเดิม มีริ้วรอย ร่องแก้มเริ่มลึก
          หนังตาตก คิ้วตก มุมปากตก มีเหนียงที่คอ
          ศาสตร์การนวดหน้าสามารถช่วยชะลอวัยและแก้ปัญหาเหล่านี้ได้และเห็นผลอย่างรวดเร็ว
        </p>
        <h2>ศาสตร์การนวดหน้า</h2>
        <p className="title text-justify">
          การปรนนิบัติผิวหน้าด้วยวิถีธรรมชาติ (ออร์แกนิก)
          พร้อมด้วยศาสตร์การกดจุดผ่อนคลาย การกระตุ้นการไหลเวียนเลือด
          การออกกำลังผิวหน้าถึงชั้นมัดกล้ามเนื้อด้วย{" "}
          <span className="hilight">"The power of the hands" </span>{" "}
          ที่มีท่านวดที่เป็นเอกลักษณ์
        </p>
        <p className="title text-justify">
          พลังของมือจาก Therapitst (เทอราปิส) ผู้เชี่ยวชาญด้านศาสตร์ ชะลอวัย
          ความอบอุ่นที่อุณหภูมิ 37 องศา ผิวสัมผัสที่แตกต่างจากเครื่องมือใดใด
          ผสานกับการบำรุงล้ำลึกจากผลิตภัณฑ์ออร์แกนิกคุณภาพระดับพรี่เมี่ยม{" "}
          <span className="hilight">
            จึงเกิดเป็นผลลัพธ์กระตุ้นและกระชับ
            กล้ามเนื้อที่หย่อนคล้อยให้กลับมาเต่งตึงมีชีวิตชีวาคืนความอ่อนเยาว์ให้ผิวหน้าคุณ
          </span>
        </p>
      </div>
      <div className="mt-5 mb-5">&nbsp;</div>
      <div className="container text-center mt-5">
        <h1>บริการ</h1>
        <div className="line-center "></div>
      </div>
      <div className="container-fluid">
        <div className="mt-5 mb-5">
          <div className="row">
            <div className="col-md-6 p-0">
              <Img fluid={data.lifting.childImageSharp.fluid} />
            </div>
            <div className="col-md-6 p-5">
              <h4 className="">นวดหน้ายกกระชับ V-Shape (Make Me Lift) </h4>
              <div className="line"></div>
              <p className="title text-justify">
                ทำความสะอาดผิว นวดยกกระชับถึงชั้นมัดกล้ามเนื้อ กดจุดกระตุ้นการไหลเวียนเลือด เพิ่มเลือดฝาด มาส์กบำรุงเพิ่มความชุ่มชื้นแก่ผิวหน้า 
              </p>
              <p className="title text-justify">
                • หน้าเรียว 
                • ลดริ้วรอย 
                • เติมน้ำให้ผิว
              </p>
            </div>
            <div className="col-md-6 p-5">
              <h4 className="">นวดหน้าขจัดสารพิษ (Make Me Bright)</h4>
              <div className="line"></div>
              <p className="title text-justify">
                  นวดเดรนของเสียออกจากระบบนำ้เหลืองบนหน้า ด้วยเทคนิคชั้นสูง ฟื้นฟูผิวอ่อนล้า อย่างอ่อนโยน ผิวแข็งแรงจากภายใน กระจ่างใสสู่ภายนอก
              </p>
              <p className="title text-justify">
                • ขับสารพิษ 
                • ลดอาการบวมน้ำ 
                • ผิวหน้ากระจ่างใสทันที
              </p>
            </div>
            <div className="col-md-6 p-0">
              <Img fluid={data.detox.childImageSharp.fluid} />
            </div>

            <div className="col-md-6 p-0">
              <Img fluid={data.hermithead.childImageSharp.fluid} />
            </div>
            <div className="col-md-6 p-5">
              <h4 className="">นวดศรีษะ (Hermit's Head)</h4>
              <div className="line"></div>
              <p className="title text-justify">
                นวดผ่อนคลาย + บำรุง เส้นผมและหนังศีรษะ รวมถึง คอ บ่า ไหล่ ช่วยบรรเทาอาการออฟฟิคซิโดรม ปวดหัวไมเกรน นอนหลับยากนอนหลับไม่ลึก 
              </p>
              <p className="title text-justify">
                • ผ่อนคลาย 
                • ช่วยลดอาการนอนหลับยาก 
                • บำรุงหนังศรีษะ
              </p>
            </div>
            <div className="col-md-6 p-5">
              <h4 className="">นวดรอบดวงตา (Make Me Eyes Bright)</h4>
              <div className="line"></div>
              <p className="title text-justify">
                บริหารมัดกล้ามเนื้อรอบดวงตา ด้วยการนวดในเทคนิคเฉพาะ ฟื้นฟูความชุ่มชื้นความชุ่มชื่นรอบดวงตาด้วย วิตามินซี และ เซรั่มเข้มข้น ผ่อนคลายความเมื่อยล้ารอบดวงตาพร้อมบำรุงอย่างขีดสุด  
              </p>
              <p className="title text-justify">
                • ยกกระชับรอบดวงตา 
                • ลดริ้วรอยรอบดวงตา 
                • บำรุงด้วยเซรั่ม 5 ชนิด
              </p>
            </div>
            <div className="col-md-6 p-0">
              <Img fluid={data.miracleeyes.childImageSharp.fluid} />
            </div>

            <div className="col-md-6 p-0">
              <Img fluid={data.skincheckup.childImageSharp.fluid} />
            </div>
            <div className="col-md-6 p-5">
              <h4 className="">ตรวจสภาพผิว (Skin Check Up)</h4>
              <div className="line"></div>
              <p className="title text-justify">
                เพราะผิวหน้าแต่ละคนนั่นไม่เหมือนกัน เป็นได้ทั้งผิวมัน ผิวแห้ง
                ผิวผสมหรือบอบบางแพ้ง่าย การตรวจและวิเคราะห์ผิว
                จะช่วยให้สามารถเลือกวิธีการดูแลผิวหน้าได้อย่างถูกต้องเหมาะสม
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-5 mb-5">&nbsp;</div>
      <div className="container">
        <div className="mt-5 mb-5">
          <div className="row">
            <div className="col-12">
              <h2 className="text-center">แกลเลอรี่</h2>
              <div className="line-center "></div>
            </div>
          </div>
          <div className="row">
            <div className="col-6 col-md-4 mt-3">
              <Img fluid={data.gallery0101.childImageSharp.fluid} />
            </div>
            <div className="col-6 col-md-4 mt-3">
              <Img fluid={data.gallery0102.childImageSharp.fluid} />
            </div>
            <div className="col-6 col-md-4 mt-3">
              <Img fluid={data.gallery0103.childImageSharp.fluid} />
            </div>
            <div className="col-6 col-md-4 mt-3">
              <Img fluid={data.gallery0201.childImageSharp.fluid} />
            </div>
            <div className="col-6 col-md-4 mt-3">
              <Img fluid={data.gallery0202.childImageSharp.fluid} />
            </div>
            <div className="col-6 col-md-4 mt-3">
              <Img fluid={data.gallery0203.childImageSharp.fluid} />
            </div>
            <div className="col-6 col-md-4 mt-3">
              <Img fluid={data.gallery0301.childImageSharp.fluid} />
            </div>
            <div className="col-6 col-md-4 mt-3">
              <Img fluid={data.gallery0302.childImageSharp.fluid} />
            </div>
            <div className="col-6 col-md-4 mt-3">
              <Img fluid={data.gallery0303.childImageSharp.fluid} />
            </div>
            <div className="col-6 col-md-4 mt-3">
              <Img fluid={data.gallery0401.childImageSharp.fluid} />
            </div>
            <div className="col-6 col-md-4 mt-3">
              <Img fluid={data.gallery0402.childImageSharp.fluid} />
            </div>
            <div className="col-6 col-md-4 mt-3">
              <Img fluid={data.gallery0403.childImageSharp.fluid} />
            </div>
          </div>
        </div>
      </div>

      
    </Layout>
  )
}

export default IndexPage
